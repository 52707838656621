import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Text from "atoms/Text";

const Footer = ({
  footerImgSrc,
  footerImgWidth,
  footerImgHeight,
  footerImgAlt,
  bg,
  className,
}) => {
  const myLoader = ({ src }) => src;
  return (
    <footer className={`${bg ? bg : ""} ${className} px-4 py-6`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-center items-center">
          <Text className="mr-1 text-xxs font-light">Powered By </Text>
          <div className={`${footerImgWidth} ${footerImgHeight} relative`}>
            <Image
              id="img_tag_94"
              loader={myLoader}
              src={`${footerImgSrc}`}
              alt={`${footerImgAlt}`}
              layout="fill"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
Footer.propTypes = {
  footerImgSrc: PropTypes.string,
  footerImgWidth: PropTypes.number,
  footerImgHeight: PropTypes.number,
  footerImgAlt: PropTypes.string,
};
