import Image from "next/image";
import React from "react";

const CandidateLoginContent = ({ companyDetails }) => {
  const myLoader = ({ src }) => src;
  
  return (
    <div className="h-full w-full bg-[url('/images/login-bg.jpg')] bg-cover ">
      <div className="flex flex-col h-full w-full">
        <div className="flex-none w-full md:p-10 p-6">
          {/* Need to add company specific logo if slug is priovided in URL */}
          {companyDetails ? (
            companyDetails.slug !== 'fedex' ? (<div className="relative min-w-44 max-w-68 min-h-[75px]">
            <Image
              id="img_tag_148"
              loader={myLoader}
              src={companyDetails.logo}
              alt="logo"
              layout="fill"
              objectFit="contain"
            />
            </div>) : (
            <Image
              id="img_tag_148"
              loader={myLoader}
              src={companyDetails.logo}
              width={companyDetails.slug === "fedex" ? "88" : "120"}
              height={companyDetails.slug === "fedex" ? "25" : "57"}
              alt="logo"
            />
            )
          ) : (
            <Image
              id="img_tag_148"
              src="/images/looplogo.png"
              width="170"
              height="57"
              alt="logo"
            />
          )}
          {/* <Image id='img_tag_148' loader={myLoader} src="/images/looplogo.png" width="170" height="57" alt="logo" /> */}
        </div>
        {/* <div className="flex-1 w-full md:p-3 p-6 flex justify-center items-center">
          {
            //Still not finalized as to do we show this text or not
            <span className="md:w-5/6 gap-4 flex flex-col pt-10 pb-8">
              <p className="md:text-4xl text-2xl font-bold text-white ">
                Your Success Story Begins Here Nurturing Candidates, Building
                Empires.
              </p>
              <p className="md:text-xl text-base text-white">
                We believe in the power of nurturing candidates, fostering their
                growth, and building empires together
              </p>
              <hr className="w-10/100 h-2 bg-primary-900 border-none"></hr>
            </span>
          }
        </div> */}
      </div>
    </div>
  );
};

export default CandidateLoginContent;
